import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../willoughby/layout"


export default function TravelHome({ data }) {
  const letters = data.allMdx.edges.map(item => item.node )

  function extractSlug (contentFilePath) {
    if (typeof contentFilePath === 'string') {
        const a = contentFilePath.split('/')
        const b = a[a.length - 1]
        const c = b.split('.')[0]
        return c
    } else {
        return ''
    }
  }

  return (
    <Layout>
      <div className="content-container">
        <h1 className="l1-page-title">Annual Letters</h1>
        <br />
        <div className='l1-grid-container'>
            {letters.map(node => (
              <Link to={extractSlug(node.internal.contentFilePath)} className='grid-card-container' >
                <div className='grid-card'>
                    <GatsbyImage image={node.frontmatter.featureImage.childImageSharp.gatsbyImageData} alt={node.frontmatter.title} objectFit="cover" />
                  <div className='card-title-module ctm-large-font'>
                    <h3 className='card-title'>{node.frontmatter.title}</h3>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <title>Annual Letters - Lees-Craig Family</title>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
        filter: {internal: {contentFilePath: {regex: "/src/year-in-review//"}}}
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          internal {
            contentFilePath
          }
          frontmatter {
            title
            date
            featureImage {
              childImageSharp {
                gatsbyImageData(
                  width: 1600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                }
              }
  
          }
        }
      }
    }  
}
`